<template>
  <v-app>
    <v-card width="98%" class="mx-auto pa-5">
      <v-card-title class="justify-center">{{
        $t("ご本人様確認")
      }}</v-card-title>
      <v-card-text>
        {{
          $t(
            "ご購入の前にご本人様確認を行わせていただきます。登録いただいた電話番号にSMS（ショートメッセージ）を送信しますので、メッセージに記載された6桁の番号を入力して「承認」を選択してください。"
          )
        }}
        <br />
        {{
          $t(
            "ご登録電話番号が異なる際やSMSが受信できない場合は、サポートセンター（050-3637-3860）へご連絡ください。"
          )
        }}
        <br />
        {{ $t("送信先の携帯電話") }}:
        {{ phoneNumber }}
      </v-card-text>
    </v-card>

    <v-card width="98%" class="mx-auto mt-2 pa-5" v-if="is_recaptcha">
      <div id="recaptcha-container"></div>
    </v-card>

    <v-card width="98%" class="mx-auto mt-2 pa-5" v-if="is_verification">
      <!-- 認証コードの入力 -->
      <v-text-field
        v-model="verificationCode"
        :label="$t('受信した確認コード6桁を入力してください')"
        placeholder="123456"
      ></v-text-field>
      <v-btn outlined color="green" @click="verifyCode">{{ $t("承認") }}</v-btn>
    </v-card>
  </v-app>
</template>

<script>
import "firebase/auth"; // Firebase Authentication モジュールをインポート
import { Firebase } from "../config/firebase";
export default {
  name: "twofucter",
  data() {
    return {
      is_recaptcha: true,
      is_verification: false,
      clinic: null,
      order: null,
      user: null,
      phoneNumber: "",
      verificationCode: "",
      recaptchaVerifier: null,
      confirmationResult: null,
      recaptchaVerified: false, // reCAPTCHA の認証が成功したかを追跡
    };
  },
  computed: {
    order_id() {
      return this.$route.params.id;
    },
    clinic_id() {
      return this.order.clinic_id;
    },
    saler_id() {
      return this.order.saler_id;
    },
    patient_id() {
      return this.order.patient_id;
    },
    priscription_id() {
      return this.order.priscription.id;
    },
  },
  created() {
    this.fetchUser();
    this.fetchOrder();
  },
  mounted() {
    this.initializeRecaptcha(); // コンポーネントのマウント時にreCAPTCHAを初期化
  },
  methods: {
    fetchUser: async function () {
      const userRef = await Firebase.firestore()
        .collection("Users")
        .doc(this.$store.getters.user.uid);
      await userRef.get().then((doc) => {
        if (doc.exists) {
          this.user = doc.data();
          this.user.id = doc.id;
          this.phoneNumber = this.user.phone;
        } else {
          console.log("No user document!");
        }
        this.error = true;
      });
    },
    fetchOrder: async function () {
      const orderRef = await Firebase.firestore()
        .collection("Orders")
        .doc(this.order_id);
      await orderRef.get().then((doc) => {
        if (doc.exists) {
          this.order = doc.data();
          this.order.id = doc.id;
        } else {
          console.log("No order document!");
        }
        this.error = true;
      });
    },
    regist() {
      this.$router.push({ name: "signup", params: { id: this.id } });
    },
    login() {
      this.$router.push({ name: "login", params: { id: this.id } });
    },
    // reCAPTCHA を表示し、成功時に SMS を送信する準備
    initializeRecaptcha() {
      // すでにreCAPTCHAが認証済みの場合、再度作成しない
      if (!this.recaptchaVerifier) {
        this.recaptchaVerifier = new Firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "normal",
            callback: (response) => {
              // reCAPTCHAの検証が成功した時
              this.recaptchaVerified = true; // reCAPTCHA の成功状態を保持
              console.log("reCAPTCHA verified", response);
              this.sendPhoneNumberVerification();
            },
            "expired-callback": () => {
              // reCAPTCHAの有効期限が切れた場合
              this.recaptchaVerified = false; // 認証の状態をリセット
              console.log("reCAPTCHA expired");
            },
          }
        );

        // reCAPTCHA をレンダリング
        this.recaptchaVerifier.render();
      }
    },

    // 電話番号にSMS送信
    sendPhoneNumberVerification() {
      Firebase.auth()
        .currentUser.linkWithPhoneNumber(
          this.convertToInternationalNumber(this.phoneNumber),
          this.recaptchaVerifier
        )
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          alert("SMSが送信されました");
          this.is_recaptcha = false;
          this.is_verification = true;
        })
        .catch((error) => {
          console.error("SMS送信エラー:", error);
        });
    },

    // 認証コードの検証
    verifyCode() {
      this.confirmationResult
        .confirm(this.verificationCode)
        .then((result) => {
          alert("電話番号の認証が成功しました");
          console.log(result);
          this.checkout(); // 認証成功後のリダイレクト
        })
        .catch((error) => {
          alert("認証コードが無効です:");
          console.error("認証コードが無効です:", error);
        });
    },
    checkout: async function () {
      this.$router.push({
        name: "checkout",
        params: {
          id: this.order.id,
        },
      });
    },
    convertToInternationalNumber(phoneNumber) {
      // 空白やダッシュを取り除く
      phoneNumber = phoneNumber.replace(/\s|-/g, "");

      // 既に国番号が付いているか確認
      if (phoneNumber.startsWith("+81")) {
        return phoneNumber; // 既に国際番号形式の場合、そのまま返す
      }

      // 日本の携帯電話番号のフォーマットをチェック
      if (phoneNumber.startsWith("0")) {
        // 先頭の "0" を取り除いて "+81" を追加
        return "+81" + phoneNumber.substring(1);
      } else {
        throw new Error("無効な電話番号フォーマットです。");
      }
    },
  },
};
</script>
